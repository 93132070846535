<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">ユーザー管理 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="inlineBlock searchSpace">
            <div>氏名</div>
            <div>
              <b-form-input
                v-model="filter.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="inlineBlock searchSpace searchLong">
            <div>メールアドレス</div>
            <div>
              <b-form-input
                v-model="filter.mail_address"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="inlineBlock searchSpace">
            <div>生年月日</div>
            <div>
              <b-form-input
                v-model="filter.birth"
                placeholder="入力してください。"/>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          table-class="cmsUserTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="userList"
          :fields="header"
          :filter="filter"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          @sort-changed="onSortChanged"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">全選択/全解除</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedUserIdList($event, row.item.id)"
                :checked="selectedUserIdList"
                :value="row.item.id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(tie)="row">
            <b-link
              @click="openTieModal(row.item.id)"
              v-b-modal.GasUserTieModal>
              設定
            </b-link>
          </template>
        </b-table>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        @click="destroy"
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        >削除</b-button>
    </div>


    <b-modal
      id="GasUserTieModal"
      title-class="titleMsg"
      body-class="modalBox"
      content-class="p-2"
      title=""
      size="xl"
      ok-title=""
      ok-variant="secondary"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
      :no-close-on-backdrop="true"
    >
      <div>
        <p class="mb-0 bold">紐付け済申請一覧</p>
        <b-table striped hover
          id='tieSearchTable'
          table-class="table"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          show-empty
          :fields="tieHeader"
          :items="tieList"
        >
          <template v-slot:empty="scope">
            <div class="flex flexCenter">紐付けしている申請はありません。</div>
          </template>
          <template v-slot:cell(btn)="row">
            <b-button
              @click="deleteTie(row.item.id, row.item.isPast)"
              variant="primary"
              class="tie-save-btn bold"
              >解除</b-button>
          </template>
        </b-table>

        <div id="serchWrap" class="mt-4">
          <div class="bold">絞り込み条件</div>
          <div class="search_area flex flexCenter">
            <div class="search-short inlineBlock search-space">
              <div>ターゲット</div>
              <div>
                <b-form-select
                  v-model="tieFilter.isPast"
                  :options="tieIsPastOption">
                </b-form-select>
              </div>
            </div>
            <div class="search-short inlineBlock search-space">
              <div>助成種別</div>
              <div>
                <b-form-select
                  v-model="tieFilter.series"
                  :options="tieSeriesOption">
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="inlineBlock search-space">
              <div>氏名</div>
              <div>
                <b-form-input
                  v-model="tieFilter.name"
                  placeholder="入力してください。"/>
              </div>
            </div>
            <div class="search-short inlineBlock search-space">
              <div>年度</div>
              <div>
                <b-form-select
                  v-model="tieFilter.year"
                  :options="tieYearOption">
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="">
              <div class="search-upper-space"></div>
              <b-button
                :disabled="!InputedTieFilter"
                @click="tieSearch"
                variant="primary"
                class="tie-search-btn bold"
                >検索</b-button>
            </div>
          </div>
        </div>

        <div
          v-if="tieSearchList.length > 4"
          class="my-3 flex flexCenter mx-auto">
          <b-button
            @click="$bvModal.hide('GasUserTieModal')"
            class="btn btn-primary btn-lg bold ml-4"
            >閉じる</b-button>
        </div>

        <p class="mb-0 bold">紐付け候補一覧</p>
        <b-table striped hover
          id='tieSearchTable'
          table-class="table"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          show-empty
          :fields="tieSearchHeader"
          :items="tieSearchList"
        >
          <template v-slot:empty="scope">
            <div class="flex flexCenter">{{noDataMsg}}</div>
          </template>
          <template v-slot:cell(btn)="row">
            <b-button
              :disabled="row.item.isDone"
              @click="saveTie(row.item.id, row.item.isPast)"
              variant="primary"
              class="tie-save-btn bold"
              >設定</b-button>
          </template>
        </b-table>
      </div>

        <div class="mt-3 flex flexCenter mx-auto">
          <b-button
            @click="$bvModal.hide('GasUserTieModal')"
            class="btn btn-primary btn-lg bold ml-4"
            >閉じる</b-button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'CmsUsersList',
  data() {
    return {
      dbUserList: [],
      filterdIdList: [],
      selectedUserIdList: [],
      filter: {
        name: '',
        mail_address: '',
        birth: '',
      },
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'mail_address', label: 'メールアドレス', sortable: true },
        { key: 'birth', label: '生年月日', sortable: true },
        { key: 'created_at', label: '登録日時', sortable: true },
        { key: 'tie', label: '申請紐付', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      // 以下紐付け
      popupUserId: null,
      tieFilter: {
        isPast: 0,
        name: '',
        series: null,
        year: null,
      },
      tieYearOption: [],
      dbTieSeriesOption: [],
      tieSearchList: [],
      tieSearched: false,
      tieList: [],
      sortBy: null,
      sortDesc: false,
      tableShowData: [],
    };
  },
  methods: {
    onSortChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.setSortedData();
    },
    setSortedData() {
      if (this.sortBy) {
        const sortedData = this.tableShowData.slice().sort((a, b) => {
          let result = 0;
          if (a[this.sortBy] < b[this.sortBy]) {
            result = -1;
          } else if (a[this.sortBy] > b[this.sortBy]) {
            result = 1;
          }
          return this.sortDesc ? -result : result;
        });
        this.filterdIdList = sortedData.map((data) => {
          return data.id;
        });
      }
    },
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameFilter = search.name === '' || lineData.name.includes(search.name);
      const mailFilter = search.mail_address === '' || lineData.mail_address.includes(search.mail_address);
      const birthFilter = search.birth === '' || String(lineData.birth).includes(search.birth);
      return nameFilter && mailFilter && birthFilter;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.filterdIdList = filteredItems.map((data) => {
        return data.id;
      });
      this.currentPage = 1;
      this.tableShowData = filteredItems;
      this.setSortedData();
    },
    async initFetch() {
      const response = await api.send('/api/cms/user/gas/list')
        .catch((err) => {
          console.log(err);
        });
      this.dbUserList = response.data.userList;
      this.filterdIdList = this.dbUserList.map((data) => {
        return data.id;
      });
      this.tieYearOption = response.data.yearList;
      this.dbTieSeriesOption = response.data.seriesList;
    },
    async destroy() {
      const number = this.selectedUserIdList.length;
      const msg = `${number}件のユーザーが選択されています。\n削除してもよろしいですか？`;
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        userList: this.selectedUserIdList,
      };
      const response = await api.send('/api/cms/user/gas/destroy', param)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        this.alert('ユーザーの削除に失敗しました。');
        return;
      }
      this.alert('ユーザーを削除しました。');
      this.initFetch();
      this.$store.dispatch('page/offWaiting');
    },
    allSelect() {
      this.selectedUserIdList = this.filterdIdList.slice();
    },
    allRemove() {
      this.selectedUserIdList = [];
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((userId) => {
        this.updateSelectedUserIdList(true, userId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((userId) => {
        this.updateSelectedUserIdList(false, userId);
      });
    },
    updateSelectedUserIdList(event, userId) {
      if (event) {
        // つける
        if (this.selectedUserIdList.indexOf(userId) === -1) {
          this.selectedUserIdList.push(userId);
        }
      } else {
        // 外す
        const index = this.selectedUserIdList.indexOf(userId);
        if (index !== -1) {
          this.selectedUserIdList.splice(index, 1);
        }
      }
    },
    async openTieModal(userId) {
      this.popupUserId = userId;
      await this.getTieList();
      this.$bvModal.show('GasUserTieModal');
    },
    async getTieList() {
      this.$store.dispatch('page/onLoading');
      const param = {
        user_id: this.popupUserId,
      };
      const res = await api.send('/api/cms/user/gas/tie/list', param)
        .catch((error) => {
          console.log(error);
          return false;
        });
      this.tieList = this.makeTieList(res.data.sysList, res.data.pastList);
      this.$store.dispatch('page/offLoading');
    },
    async tieSearch() {
      this.$store.dispatch('page/onLoading');
      const param = {
        isPast: this.tieFilter.isPast,
        name: this.tieFilter.name,
        series: this.tieFilter.series,
        year: this.tieFilter.year,
        user_id: this.popupUserId,
      };
      const res = await api.send('/api/cms/user/gas/tie/search', param)
        .catch((error) => {
          console.log(error);
          return false;
        });
      this.tieSearchList = this.makeSearchList(res.data.tieSearchList, this.tieFilter.isPast);
      this.tieSearched = true;
      this.$store.dispatch('page/offLoading');
    },
    makeSearchList(tieSearchList, isPast) {
      return tieSearchList.map((data) => {
        let status;
        if (isPast) {
          status = data.status === 1 ? '採択' : '不採択';
        } else {
          status = CONST_STATUS.APP_STATUS_TEXTS[data.status];
        }
        return {
          id: data.id,
          user_name: data.user_name,
          apptype_name: data.apptype_name,
          code: isPast ? '-' : data.code,
          status,
          isDone: !data.not_tie,
          isPast,
        };
      });
    },
    makeTieList(sysList, pastList) {
      const tieList = [];
      sysList.forEach((data) => {
        const line = {
          id: data.id,
          user_name: data.user_name,
          apptype_name: data.typeName,
          year: data.year,
          code: data.code,
          status: CONST_STATUS.APP_STATUS_TEXTS[data.status],
          isPast: false,
        };
        tieList.push(line);
      });
      pastList.forEach((data) => {
        const line = {
          id: data.id,
          user_name: data.user_name,
          apptype_name: data.typeName,
          year: data.year,
          code: '-',
          status: data.status === 1 ? '採択' : '不採択',
          isPast: true,
        };
        tieList.push(line);
      });
      console.log(tieList);
      return tieList;
    },
    async saveTie(appId, isPast) {
      this.$store.dispatch('page/offLoading');
      const param = {
        userId: this.popupUserId,
        appId,
        isPast,
      };
      const res = await api.send('/api/cms/user/gas/tie/save', param)
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (!res) {
        await this.alert('設定に失敗しました');
        this.$store.dispatch('page/offLoading');
        return;
      }
      await this.getTieList();
      await this.tieSearch();
      await this.alert('登録しました。');
      this.$store.dispatch('page/offLoading');
    },
    async deleteTie(id) {
      this.$store.dispatch('page/offLoading');
      const param = {
        id,
      };
      const res = await api.send('/api/cms/user/gas/tie/destroy', param)
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (!res) {
        await this.alert('解除に失敗しました');
        this.$store.dispatch('page/offLoading');
        return;
      }
      await this.getTieList();
      await this.tieSearch();
      await this.alert('解除しました。');
      this.$store.dispatch('page/offLoading');
    },
  },
  computed: {
    userList() {
      return this.dbUserList.map((data) => {
        return {
          id: data.id,
          name: `${data.mst_user_info.sei} ${data.mst_user_info.mei}`,
          kana: `${data.mst_user_info.kana_sei} ${data.mst_user_info.kana_mei}`,
          mail_address: data.login_id,
          birth: moment(data.mst_user_info.barth).format('YYYY/MM/DD HH:mm:ss'),
          created_at: moment(data.created_at).format('YYYY/MM/DD HH:mm:ss'),
        };
      });
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    tieIsPastOption() {
      return [
        { value: 0, text: 'システム内申請' },
        { value: 1, text: 'システム化前申請' },
      ];
    },
    tieSeriesOption() {
      return this.dbTieSeriesOption.map((data) => {
        return {
          value: data.id,
          text: data.name,
        };
      });
    },
    InputedTieFilter() {
      const inputedIsPast = this.tieFilter.isPast === 1 || this.tieFilter.isPast === 0;
      const inputedSeries = this.tieFilter.series !== null;
      // const inputedName = this.tieFilter.name !== '';
      // const inputedYear = this.tieFilter.year !== null;
      return inputedIsPast && inputedSeries;
    },
    tieSearchHeader() {
      return [
        { key: 'user_name', label: '申請者名', sortable: true },
        { key: 'apptype_name', label: '助成種別', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'status', label: 'ステータス', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ];
    },
    tieHeader() {
      return [
        { key: 'user_name', label: '申請者名', sortable: true },
        { key: 'apptype_name', label: '助成種別', sortable: true },
        { key: 'year', label: '年度', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'status', label: 'ステータス', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ];
    },
    noDataMsg() {
      let msg;
      if (this.tieSearched) {
        msg = '検索条件一致するデータはありませんでした。';
      } else {
        msg = 'ターゲット、助成種別を選択して検索してください。';
      }
      return msg;
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .cmsUserTable thead th:nth-of-type(1) {
    width: 140px !important;
  }

  #tieSearchTable thead th:nth-of-type(4) {
    width: 120px !important;
  }
  #tieSearchTable thead th:nth-of-type(5) {
    width: 100px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchLong {
    width: 450px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }

  .search-upper-space {
    height: 24px;
  }

  .tie-search-btn {
    width: 100px !important;
  }

  .tie-save-btn {
    width: 80px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
</style>
